<template>
    <div>
        <v-breadcrumbs
            :items="items"
            divider="-"
        ></v-breadcrumbs>
        <v-divider></v-divider>
    
    <div class="m-4">
        <v-simple-table 
            width="100%"
            id="MainTable"
            hide-default-footer
            class="table-sm m-1"
        >
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-center">الاسم الكامل</th>
                        <th class="text-center">رقم الاتصال</th>
                        <th class="text-center">اسم الشركة</th>
                        <th class="text-center">رقم الهوية / الاقامة</th>
                        <th class="text-center">الجنسية</th>
                        <th class="text-center">المدينة</th>
                        <th class="text-center">
                            الاجراءات
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in rRows" :key="index">
                        <td class="text-center">{{item.myname}}</td>
                        <td class="text-center">{{item.mobile}}</td>
                        <td class="text-center">{{item.mycompany}}</td>
                        <td class="text-center">{{item.myid}}</td>
                        <td class="text-center">{{item.nationality}}</td>
                        <td class="text-center">{{item.mycity}}</td>
                        <td class="text-center" style="width:150px;">
                            <b-button variant="danger" style="margin-right:5px;" class="btn-sm" @click="reject(item.id)">رفـض</b-button>  
                            <b-button variant="success" style="margin-right:5px;" class="btn-sm" @click="register(item)" v-b-toggle.add_employee>تسجيل</b-button>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <addEmployee :eid="empid" :eitem="emitem" />
        <vue-snotify></vue-snotify>
    </div>
    </div>
</template>

<script>
import axios from 'axios'
import addEmployee from '@/components/addEmployee.vue'
export default{
    components:{addEmployee},
    name: 'Registerd',
    data() {
        return {
            empid:0,
            emitem:'',
            rRows: [],
            items:[
                // {
                //     text: this.$store.state.hometitle,
                //     disabled: false,
                //     to: '/home',
                // },
                {
                    text: 'طلبات التسجيل',
                    disabled: false,
                    to: '/Register',
                },
            ]
        }
    },
    methods: {
        reject(id){
            const post = new FormData();
            post.append("type" , "rejectRegis");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[id]",id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((res) => {
                this.$snotify.success('تم رفض تسجيل المندوب', 'اضافة عميل', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                this.getData();
            })
        },
        register(id){
            this.empid = id.id;
            this.emitem = id;
        },
        getData(){
            const post = new FormData();
            post.append("type" , "getRegisterd");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[all]","all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((res) => {
                this.rRows = res.data.results.data;
            })
        }
    },
    created() {
        this.getData();
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
}
</script>